<script>
import { SuawMainContent, SuawParagraph, SuawHeading } from "@suaw/suaw-component-library";
import ArticleFrame from "@/features/articles/components/ArticleFrame.vue";

export default {
  name: "TheWritersGuideToEditors",
  components: {
    SuawMainContent,
    SuawParagraph,
    SuawHeading,
    ArticleFrame
  }
};
</script>

<template>
  <SuawMainContent size="large">
    <ArticleFrame article-id="TheWritersGuideToEditors">
      <SuawParagraph
        text="At long last, you have a completed draft in hand. Or maybe you don’t, but you’d really like to have one someday. Whether you have a polished piece or a half-finished project, you’ll likely face this age-old question: do you need an editor?"
      />
      <SuawParagraph text="The answer is a resounding “yes.”&nbsp;" />
      <SuawHeading level="3" content="Why do you need an editor?" />
      <SuawParagraph
        text="<ul><li>Improve your writing: Writing is a craft, and like all crafts, it can be improved with practice and feedback. Receiving feedback from an editor is a valuable way to learn your strengths and challenges as a writer. You might find out that you have an addiction to semicolons, or a habit of accidentally changing your point of view mid-scene. An editor can point out a multitude of sins and help you learn from your mistakes.</li><li>Change your perspective: An editor can provide a fresh perspective that may force you to see your project in a new way. Your editor’s point of view can also be a great source of inspiration when your project has hit a roadblock.</li><li>Solidify your ideas: Discussing your writing with an editor can help your project become clearer in your own mind. When you explain your project to someone else, you’re forced to put your ideas into words and bring your thoughts into focus. Your editor may ask questions that push you to solve problems and discover new aspects to your piece.</li><li>Give yourself a break: Have you heard the common phrase, “you are your own worst critic?” Writers often fall victim to underestimating (or overestimating) the quality of their own work. An editor will allow you to take a step back and let someone else think about your draft, and will provide feedback that is more objective than your own opinions.</li><li>Practice taking criticism: It’s not easy to hand off the precious fruits of your labor to someone else, which is why it’s a good idea to do it anyway. Learning how to take criticism and work with an editor is a valuable skill for writers, and it’s something that improves with experience. In the same vein, learning how to find an editor who is the right fit for your needs is also a valuable skill.</li></ul>"
      />
      <SuawParagraph
        text="But how do you go about finding that “right fit” editor? First, be sure you’re familiar with the different types of editing available to you:&nbsp;"
      />
      <SuawParagraph
        text="<ul><li>Developmental editing: Sometimes called structural editing, this type of editing focuses on the foundational aspects of writing, including structure, ideas, and organization. A developmental editor isn’t focused on fine-tuning each line, but on seeing the big picture and making the whole piece work. Developmental editors may propose major changes to your project, and look at overall qualities like pacing, character arcs, and plot. They often ask important questions and give extensive feedback on a draft.</li><li>Copyediting: Copyediting is the process of fine-tuning a draft with a comprehensive look at technical flaws, including style, spelling, and grammar. Colloquially, this is sometimes known as “SPAG” — Spelling and Grammar. Some editors differentiate between copyediting and line editing, with copyediting more focused on technical rules, and line editing focused on style and syntax. However, some editors consider both terms to be interchangeable, and do both at once. Overall, copyediting is a process that focuses on consistency, correct usage, and readability.</li><li>Proofreading: This is a review of a final draft for formatting and any errors that were missed in the copyedit stage. Ideally, a draft should have already been edited at least once before it is proofread. Once your document is proofread, it should be ready to submit.</li></ul>"
      />
      <SuawHeading level="3" content="At what point during the writing process should you bring in an editor?" />
      <SuawParagraph
        text="Like many writing-related questions, there is no one-size-fits-all approach. If you’ve reached a point in your manuscript where you feel lost or confused about next steps, consider seeking the services of a developmental editor to help you work through structure and other basic elements. If you already have a finished draft, finding a copyeditor would be a logical plan. You may get more benefit from working with an editor at an early stage in your process, or you may be the type who only needs an eagle-eyed grammar expert to swoop in at the last minute. Regardless of where you are in the writing process, if you find yourself wondering if an editor might be helpful, it might be time to start your search."
      />
      <SuawHeading level="3" content="How do you find an editor?" />
      <SuawParagraph
        text="If you’re not yet sure if you’re ready to invest in paid services, you might want to seek out a friend or fellow writer to do an unpaid read-through of your work. This type of unpaid reader is called a <strong>beta reader</strong>, much like an unpaid tester for new software is called a beta tester. Cultivating friendships with fellow writers is a wonderful way to find a beta reader, especially if you trade and read their work as well. A reciprocal beta reading relationship can be invaluable for writers, as it is incredibly educational to read and edit someone else’s writing in addition to your own. (Tip: Shut Up &amp; Write! events are excellent places to meet fellow writers…)"
      />
      <SuawParagraph
        text="Beta readers should be considered as a part of any editing process, but ultimately there is no substitute for a skilled professional editor. Once you’re ready to hire a professional editor, and you’ve determined what type of editing you need, you can start your search by using online directories, including our very own <a target='_blank' title='https://store.shutupwrite.com/collections/professional-services' href='https://store.shutupwrite.com/collections/professional-services'>Shut Up &amp; Write Professional Services</a>."
      />
      <SuawHeading level="3" content="How much can you expect to pay an editor?" />
      <SuawParagraph
        text="To find current freelance editing rates, you’ll have to do some market research, and that starts with the Search field at the search engine of your choice. Try a query for the type of editor you need — for example, “developmental editor rate,” plus the current year. Many editors charge an hourly rate for their services, but some editors charge by the word. Yet others charge a flat fee for a single project. As with many professions, editors with more experience or specialized knowledge may be more expensive."
      />
      <SuawParagraph
        text="The Editorial Freelance Association has <a target='_blank' title='EFA Rates' href='https://www.the-efa.org/rates/'>a list of rates</a>, and Reedsy.com also has a <a target='_blank' title='How to Set Freelance Editing Rates' href='https://blog.reedsy.com/freelancer/how-to-set-your-freelance-editing-rates/'>comprehensive article</a> geared for editors on how to set rates."
      />
      <SuawHeading level="3" content="What questions can you ask an editor to determine if they’re a good fit?" />
      <SuawParagraph
        text="Hiring an editor is just like hiring any other professional — you need to determine their experience and qualifications for the job you’re offering. Besides asking about their work experience, you may also want to ask if their particular specialties fit your project’s needs.&nbsp;"
      />
      <SuawParagraph text="First, it’s essential to ask an editor what type of editing services they offer — developmental, copyediting, and/or proofreading.&nbsp;" />
      <SuawParagraph
        text="You should also ask what style guide they use. If you’re writing nonfiction or an article for a magazine or news source, <a target='_blank' title='AP style' href='https://www.apstylebook.com/'>AP style</a> is the industry standard. If you’re writing a book, look for an editor who uses the <a target='_blank' title='Chicago Manual of Style' href='https://www.chicagomanualofstyle.org/'>Chicago Manual of Style</a>. Ideally, your editor should be familiar with your genre and style guide of choice."
      />
      <SuawParagraph
        text="Other important questions include their turnaround time — if you have a deadline, are they able to meet it? Also, you’ll want to ask how the editor actually submits edits. Some editors may prefer old-school pen and paper, while some use the Track Changes feature in Microsoft Word, and yet others rely on the Comments feature in Google Docs."
      />
      <SuawParagraph
        text="Establish your needs in terms of communication. If you’d like a working relationship with an editor that includes frequent back-and-forth emails, phone calls, or discussions, be sure to mention that upfront. Some editors work more independently and communicate when they’ve finished a project, while some are ready for in-depth chat sessions. Be clear about the work style that is best for you."
      />
      <SuawParagraph text="As with any interview, don’t forget to ask for work samples or past testimonials." />
      <SuawHeading level="3" content="Now that you have your editor, what happens next?" />
      <SuawParagraph
        text="Prepare to rework, rethink, and revise. Learning how to take criticism in order to improve a draft is one of the most valuable things a writer can take from the editing process. When you reach out to an editor, you may end up forging a relationship with someone who becomes equally invested in your project’s success. The back-and-forth volley between writer and editor can be challenging, enjoyable, and even thrilling — it’s the reason many editors are in the business. Let your editor be your ally in releasing your full potential as a writer."
      />
    </ArticleFrame>
  </SuawMainContent>
</template>
